.table {
  border-collapse: collapse;
  
  
  overflow:hidden;

  border-radius: 5px;

  font-family: sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  
  
  margin-top: 50px;

  min-width: 90vw;
}



.table thead,
.table tfoot {
background-color: #114E28;
color: #f3f3f3;
}

.table th,
.table td {
border: 1px solid #114E28;

border-right: none;
border-left: none;

padding: 15px 10px;
}

.table td {
text-align: center;
}

.table thead tr:hover{
background-color: #114E28 !important;
}

.table tr:hover{
background-color: #efefef;
}

.table input{
  border: none;
  text-align: center;
}

.container{
  display: flex;
  flex-direction: column;
}

.container .buttonsContainer{
  display: flex;
  justify-content: center;

  gap: 30px;

  padding-top: 20px;
}

.modalInnerContainer{
  display: flex;
  flex-direction: column;

  align-items: center;
  
}

.modalInnerContainer hr{
  width: 100%;

  margin-bottom: 0;
}

.modalInnerContainer h3{
  font-size: 36px;

  margin-block-start: 0;
  margin-block-end: 0;
}

.modalInnerContainer p{
  font-size: 18px;
}

.modalInnerContainer span{
  color: #c91212;

  font-weight: 900;
}

.tableContainer{
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 10vh;
}

.aviso{
  color: #c91212;
}

.pagination{
  display: flex;

  flex-direction: row;

  justify-content: space-between;
}

.pagesContainer{
  display: flex;

  flex-direction: row;
  align-items: center;

  gap: 10px;
}

.buttonContainer{
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 5px;
}

.tableButton{
  font-size: 18px;

  padding: 5px 10px;

  border: 1px solid #114E28;

  background-color: #fff;

  transition: linear 0.2s;

  max-width: fit-content;

  color: #114E28;
    
  border-radius: 5px;
}

.tableButton:hover{
  background-color: #114E28 !important;
  cursor: pointer;
  color: #f3f3f3;
}

.tableButton:disabled{
  background-color: #114E28 !important;
  cursor: pointer;
  color: #f3f3f3;
}

.headerContainer{
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.aviso span{
  font-weight: 900;
  font-size: larger;
  
  display: block;
}

@media screen and (max-width: 700px) {
  .tableContainer{
    max-width: 90vw !important;
    overflow-x: auto !important;
    overflow-y: visible !important;

    justify-content: flex-start;
    align-items: flex-start;


    min-height: fit-content !important;
  }

  .headerContainer{
    overflow-x: hidden !important;
    overflow-y: visible !important;
  }
}