.container{
    display: flex;

    flex-direction: row;

    flex-wrap: wrap;

    justify-content: space-around;

    padding-top: 20px;
}

.innerContainer{
    display: flex;

    flex-direction: column;

    gap: 20px;
}

.innerContainerAntiLavado{
    border-left: 2px solid #114E28;
    border-top: 2px solid #114E28;

    padding: 20px 50px;

    height: fit-content;
}

.text{
    font-weight: 800;

    color:#114E28
}

.header{
    display: flex;
    flex-direction: row;

    gap: 20px;

    justify-content: end;

    align-items: center;

    flex-wrap: wrap;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .container{
        justify-content: center;
        gap: 50px;
    }
    .header{
        justify-content: center;
    }
}