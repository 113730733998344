.documentsContainer{
    display: flex;

    flex-wrap: wrap;

    justify-content: space-evenly;

    padding-top: 50px;

}

.documentsContainer span{
    min-width: 400px;
}


.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;
  
    background-color: rgba(199, 199, 199, 0.825);
  
    z-index: 50;
  
    position: fixed;
    left: 0;
    top: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
  }