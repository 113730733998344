.container{
    max-width: 95vw;

    margin: 0vh auto;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.container h1{
    font-size: 64px;
    margin-bottom: 20px;

    color: #114E28;
}

.container hr{
    margin-bottom: 20px;
    border-top: 2px solid #114E28;
}

.childContainer{
    display: flex;
    flex-direction: row;

    justify-content: space-around;
}

.labelContainer{
    position: sticky;
    position: -webkit-sticky;
    top: 40%;
    left: 0%;
    height: fit-content;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.labelContainer h3, .labelContainer label{
    color: #114E28;
}

.tagsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding-top: 20px;
    gap: 20px;
}

.innerContainer{
    display: flex;

    flex-direction: column;
    justify-content: center;

    align-items: center;

    gap: 20px;
}

.innerContainer p{
    text-align: center;
}

.selectDG{
    min-width: 300px;
    text-align: left;
}

.collapseContainer{
    display: flex;

    align-items: center;
    justify-content: center;

    flex-direction: column;
}

.collapseContainer button{
    display: block;
}

.clientContainer{
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    text-align: center;

    width: fit-content;
}

.clientContainer button{
    margin-top: 20px;
    min-width: 300px;
}

.clientContainer hr{
    min-width: 400px;
}

.infoContainer{
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 40px;
}

.collapsedButtons{
    display: flex;
    text-align: center;

    justify-content: center;
    align-items: center;

    flex-direction: column;
}

.innerCollapsedButtons{
    display: flex;

    flex-wrap: wrap;

    justify-content:center;
    gap: 20px;
    
}

.sendButton hr{
    width: 800px;
}

.sendButton button{
    display: block;
    min-width: 800px;
}

.sendButton{
    margin-top: 20px;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadContainer{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.downloadContainer a{
    background-color: #114E28;
    text-decoration: none;

    color: #fff;

    font-weight: 800;
    font-size: 18px;

    padding: 20px 30px;

    transition: .2s linear;

    border-radius: 10px;
    border: 2px #114E28 solid;
}

.downloadContainer a:hover{
    background-color: #fff;

    color: #114E28;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media screen and (max-width: 900px) {

    .container h1{
        font-size: 48px;
    }

    .sendButton hr{
        width: calc(100vw - 50px);
    }
    
    .clientContainer hr{
        min-width: calc(100vw - 50px);
    }

    .childContainer{
        flex-wrap: wrap;
    }
    
    .labelContainer{
        position: relative;
        height: fit-content;
        max-width: calc(100vw - 50px);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding-bottom: 20px;
    }
}