.container{
    background-color: #5f9473;

    position: fixed;

    min-width: 100vw;
    min-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

}

.errorContainer{
    color: #5f9473;

    background-color: #114E28;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;

    min-height: calc(100vh - 100px);
    min-width: calc(100vw - 100px);
}

.errorContainer h1{
    font-size: 10em;
    margin: 0;
}

.errorContainer h2{
    font-size: 2.5em;
    margin: 0;
}

.errorContainer p{
    font-size: 1.5em;
    margin: 0;
}

.errorContainer img{
    margin: 0;
    min-width: 15%;


}

.errorContainer a{
    color: #5f9473;
    font-weight: 700;
    transition: .15s linear;
}

.errorContainer a:hover{
    color: white;
}

@media screen and (max-width: 1000px) {
	.errorContainer h1{
        font-size: 7.5em;
    }
    
    .errorContainer h2{
        font-size: 2em;
    }
    
    .errorContainer p{
        font-size: 1em;
    }

    .errorContainer img{
        min-width: 20%;
    }
}


@media screen and (max-width: 700px) {
	.errorContainer h1{
        font-size: 5em;
    }
    
    .errorContainer h2{
        font-size: 1.25em;
    }
    
    .errorContainer p{
        font-size: 1em;
    }
}


@media screen and (max-width: 700px) {
	.errorContainer h1{
        font-size: 3.5em;
    }
    
}