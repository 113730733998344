.container{
    position: fixed;
    top: 0;
    left: 0;


    overflow-y: scroll;
    height: 100vh;

    width: 100vw;

    background-color: #fff;

    z-index: 90;

    padding-top: 5vh;
}

.innerContainer{
    margin: 50px;
}

.headerContainer{
    display: flex;

    justify-content: space-between;

    margin-bottom: 50px;

    align-items: center;

    flex-wrap: wrap;
}

.headerContainer button{
    height: 50px;
}

.headerContainer div{
    display: flex;

    justify-content: center;
    vertical-align: middle;

    gap: 20px;
}

.headerContainer h1{
    margin: 0 !important;

    font-size: 56px !important;
}

.flexContainer{
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
}

label{
    font-size: 12px;
}

.selectDG{
    min-width: 200px;

    max-width: 240px;
}

.optionalContainer{
    border-left: 2px solid #114E28;
    border-top: 2px solid #114E28;

    padding: 20px 50px;

    height: fit-content;
}


.optionalContainer span{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.text{
    font-weight: 800;
    color: #114E28;
}

@media screen and (max-width: 1000px) {
    .flexContainer{
    
        justify-content: space-evenly;

        align-items: center;

        gap: 40px;
    }
}

@media screen and (max-width: 600px) {
    .flexContainer{
    
        justify-content: center;

        align-items: center;

    }

    .flexContainer h3{
        font-size: 16px !important;
    }

    .headerContainer{
    
        justify-content: center;
        gap: 40px;
        margin-bottom: 30px;
    }
    
}