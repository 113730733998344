.clientButton{

    font-size: 18px;

    padding: 15px 30px;

    border: none;
    border-radius: 10px;

    transition: linear 0.2s;

    min-width: 100%;

    background-color: #ffffff;

}

.clientButton:hover{
    background-color: #114E28;

    cursor: pointer;

    color: #f3f3f3;
}

.clientContainer{
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: calc(90vw - 100px);
}

.clientInnerContainer{
    padding: 50px 100px;
    min-width: calc(100% - 500px);
}

.clientInnerContainer h2{
    color: #114E28;
    text-align: center;
}

.clienteContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 20px;

    min-width: max-content;

    margin-top: 20px;
}

.clientInfoContainer{

    border-top: 1px solid #114E28;

    padding: 30px 30px;

    text-align: center;
}

.clientInfoContainer ol{
    display: flex;
    justify-content:flex-start;
    flex-direction: row;

    gap: 40px;

    flex-wrap: wrap;

    min-width: calc(90vw - 100px);
}

.clientInfoContainer li {
    list-style-type: none;
    min-width: 30%;
}


.clientInfoContainer h2{
    color: #114E28;

    text-align: center;

    margin-block-start: 0;
    margin-block-end: 0;
}

.clientAddContainer{
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.clientInnerContainer button{
    display: block;
    width: 50%;
    /* min-width: 100%; */
}

.clientInnerContainer ol{
    padding-left: 0;
    min-width: max-content;
}



.selectDG{
    min-width: 350px;
    max-width: 90%;

    margin-bottom: 20px;
}

.flexClientsContainer{
    min-height: 80%;
    display: flex;

    justify-content: space-between;
    flex-direction: column;

}

.innerFlexClientsContainer{
    border-top: 1px solid #114E28;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    min-width: max-content;

    padding: 10px 30px;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

.flexContainer{
    display: flex;

    gap: 50px;

    flex-direction: row;

    flex-wrap: wrap;
}

.flexContainer div{
    padding: 15px 30px;
}

.direccion{
    border: 1px solid #114E28;
    background-color: #114E28;
    border-radius: 20px;
}

.direccion p, .direccion strong{
    color: #ffffff !important;
}

@media screen and (max-width: 850px) {
    .clienteContainer input{
        min-width: 75px !important;
        max-width: 75px !important;
    }


    .clienteContainer {
        gap: 10px;
    }

	.clientContainer{
        flex-direction: column;

        align-items: center;

        justify-content: center;
    }

    .clientInnerContainer{
        padding: 0px;
        padding-bottom: 50px;

        border-right: none;
        border-bottom: 1px solid #114E28;
    }


}

@media screen and (max-width: 500px) {

    .clientButton{
        padding: 5px 10px;

        margin: 10px 0;

        font-size: 12px;
    
    }
}