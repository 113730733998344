.wrapper{
    background-color: #fff;


    display: inline-block;
    min-width: 100%;

    border: 2px solid #114E28;

    border-radius: 10px;

    color: #114E28;

    text-align: center;

    margin: 20px 0px;

    transition: linear 0.2s;
}

.wrapper:hover{
    background: #114E28;
    color: #fff;

    cursor: pointer;

}

.wrapper:hover .title{
    color: #fff;
}

.title{
    font-size: 24px;

    color: #114E28;

    font-weight: 800;
}
