.modal{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 99;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    justify-content: center;
}

.container{
    margin: auto;

    background-color: #fff;

    width: 50vw;
    height: fit-content;

    display: inline-block;
    position: relative;

    border-radius: 20px;

    padding: 50px;
}

.modal iframe{
    max-width: 100%;
    min-height: 50vh;
    
    border: none;

    
}

.container div{
    display: flex;
    justify-content: center;

    padding-top: 20px;
    gap: 20px;
}

.close{
    width: 20px;
    height: 20px;

    border-radius: 20px;
    padding: 10px;

    position: absolute;
    top: 5px;
    right: 5px;

    background-color: #114E28;

    transition: 0.3s linear;
}

.close:hover{
    cursor: pointer;

    transform: rotate(90deg);
}

@media screen and (max-width: 900px) {
    
    .container div{
        justify-content: center;
    
        padding-top: 20px;

        flex-wrap: wrap;
        gap: 20px;
    }

    .close{
        width: 15px;
        height: 15px;
    
        padding: 10px;
    }

    .container{
        width: 70vw;
        height: fit-content;
    }
    
    
    
  }