.container{

    display: flex;
    justify-content: space-between;

    min-height: 100vh;
}

.infoContainer{
    padding-top: 10vh;
    justify-content: center;
    display: flex;

    flex-direction: column;

    margin: auto;
}

.imgContainer{
    background-size: cover;
    min-width: 40vw;
}

.infoContainer h1{
    font-size: 96px;

    font-weight: 900;

    margin-top: 0;
    margin-bottom: 0;
}

.infoContainer h2{
    font-size: 42px;

    font-weight: 500;

    margin-bottom: 0;
}

.infoContainer h3{
    font-size: 28px;

    font-weight: 500;

    margin-top: 0;

    color: #114E28;;
}

.separation{
    margin-top: 20vh;
}

.button{
    max-width: 20vw;
}

@media screen and (max-width: 1000px) {
	.infoContainer h1{
        font-size: 72px;
    }
    
    .infoContainer h2{
        font-size: 42px;
    
    }
    
    .infoContainer h3{
        font-size: 24px;
    }
}

@media screen and (max-width: 800px) {
	.infoContainer h1{
        font-size: 64px;
    }
    
    .infoContainer h2{
        font-size: 36px;
    
    }
    
    .infoContainer h3{
        font-size: 18px;
    }
}

@media screen and (max-width: 650px) {
	.container{
        display: flex;
        justify-content: center;

        flex-direction: column-reverse;

        max-height: 90vh;
    }

    .infoContainer{
        justify-content: center;
        display: flex;

        padding-top: 0;

        align-items: center;
    
        flex-direction: column;
    }
    
    .imgContainer{
        height: 40vh;
    }
}