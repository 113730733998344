.header {
  width: 100vw;
  padding: 1rem;

  top: 0;

  background-color: rgba(255, 255, 255, 0.88);

  border-bottom: solid 2px #114E28;

  backdrop-filter: blur(10px);

  position: fixed;

  z-index: 99;
}

.navbar{
  display: flex;
  justify-content: space-between;
}

.logo{
  height: 50px;
}

.list {
  display: flex;
  gap: 1rem;
  list-style: none;

  padding: 0px 20px;
}

.list a {
  text-decoration: none;
  color: black;

  font-size: 18px;

  margin: 0 10px;
  padding: 0px 10px;
}

.list a:hover,
.list a.active {
  color: #114E28;
}

.list a.active {
  font-weight: 900;
  text-decoration:overline;
}

@media screen and (max-width: 1000px) {
  .list {
    position: fixed;
    top: 0;
    background-color: rgba(17, 78, 40, 0.7);

    /* background-color: transparent; */

    margin-top: 0;

    display: none;

    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);

    width: 100vw;
    height: 100vh;

    transition: right 0.4s;

    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
  
  .logo{
    padding-left: 1rem;
  }

  .list a {
    text-decoration: none;
    color: #fff;
  
    font-size: 48px;
    font-weight: 800;
  
    margin: 0 10px;
    padding: 0px 10px;
  }

  .nav__toggle{
    font-size: 3rem;
    color: #114E28;
    cursor: pointer;

    display: flex;
    padding-right: 1rem;

  }

  .nav__close{
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    position: absolute;

    top:20px;
    right: 60px;

    transition: .2s linear;
  }

  .nav__close:hover{
    color: #114E28;
  }

  .showMenu{
    display: flex;
  }

  .header{
    display: flex;

    flex-direction: row;
    
    justify-content: space-between;

    align-items: center;

    padding: 1rem 0;
  }

  .list a.active {
    font-weight: 900;
    text-decoration:none;

    color: #082a15;
  }
 }