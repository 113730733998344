span{
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.collapse {
  overflow: hidden;
  transition: max-height 0.5s ease-out, padding 0.5s ease-out;
  max-height: 0;
  padding: 0 1rem;
  background-color: #f0f0f0;

  border-radius: 20px;

  width: 70vw;
}

.collapse button{
  display: block;

  font-size: 14px;
  padding: 10px 5px;
}

.collapse.expanded {
  max-height: fit-content; /* Adjust this value as needed */
  padding: 1rem;
}