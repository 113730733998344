.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #114E28;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    background: #114E28;
    border-color: #114E28;
    color: #fff;
    border-radius: 10px 10px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }

  @media screen and (max-width: 800px) {
    .react-tabs__tab-list {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
  
      flex-wrap: wrap;
    }

    .react-tabs__tab h3{
      font-size: 16px;
    }

    
  }