.container{
    max-width: 95vw;

    margin: 15vh auto;
}

.container h1{
    font-size: 64px;
    margin-bottom: 20px;
    color: #114E28;
}

.container h3{
    font-size: 18px;
    font-weight: 500;
}

.container hr{
    margin-bottom: 20px;
    border-top: 2px solid #114E28;
}

.selectContainer{
    min-width: 200px;

    margin-bottom: 20px;
}

.buttonContainer{
    padding-top: 20px;
    display: flex;

    justify-content: space-between;

    align-items: center;
}

.noExpediente{
    text-align: center;

    font-size: 56px !important;

    padding-top: 15px;

    margin-bottom: 0 !important;
}

.dino{
    display:block;
    width: 200px;
    margin:auto;
}

.formContainer{
    position: relative;
}

.buttonsLoader{
    display: flex;
    flex-direction: row;

    gap: 20px;

    align-items: center;

    flex-wrap: wrap;
}

.buttonsLoader p{
    text-align: center;
}

.addingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 0px !important;

    margin-bottom: 20px;
}

.addingContainer p{
    font-size: 18px;

    margin-block-end: 0px;
}


.deleteModal{
    display: inline-block !important;
  
    text-align: center;
  
    width: 100%;
  }
  
  .deleteModal p{
    font-size: 22px;
  }
  
  .deleteModalButtons{
    display: flex;
    align-items: center;
  }

@media screen and (max-width: 600px) {
	.buttonContainer{
        padding-top: 0px;
    
        justify-content: space-between;

        flex-direction: column-reverse;
    }

    .buttonContainer button{
        margin: 0px 5px;
    }

    .dino{
        display:block;
        width: 100px;
        margin:auto;
    }

    .container h1{
        font-size: 48px;
    }

    .buttonsLoader p{
        width: 100%;
    }

    .buttonsLoader{
        justify-content: center;
    }

    .selectContainer{
        margin-top: 20px;
    }
}