.text{
    text-align: center;

    font-size: 56px !important;

    padding-top: 15px;

    margin-bottom: 0 !important;
}

.dino{
    display:block;
    width: 200px;
    margin:auto;
}

@media screen and (max-width: 600px) {

    .dino{
        display:block;
        width: 100px;
        margin:auto;
    }

    .text{
        
        font-size: 36px !important;
    }
}