.selectDG{
    min-width: 200px;

    max-width: 240px;
}

.formContainer{
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    flex-wrap: wrap;

    max-width: 90vw;

    margin: auto;
}

.innerContainer{
    display: flex;

    flex-direction: column;

    align-items: stretch;

    min-height: max-content;
}

@media screen and (max-width: 600px) {
	.formContainer{
        justify-content: space-between;

        
    }
}

@media screen and (max-width: 500px) {
	.formContainer{
        justify-content: center;

        gap: 20px;
    }
}