.headerContainer{
    padding-top: 20px;
    display: flex;

    justify-content: space-between;

    align-items: center;
}

.input{
  width: 300px;

  padding: 10px 20px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;

  font-size: 18px;

  background-image: url('../img/icons/search_icon.svg');
  background-size: 30px;
  background-repeat: no-repeat;

  background-position: right;
}

.input:hover{
  border-color: #999999;
}

.input:focus{
  outline: none !important;


  border: 1.5px solid #114E28 !important;
  outline-color: #114E28 !important;
  box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.headerContainer button{
    margin: 0px 10px;
}

.table {
    border-collapse: collapse;
    
    
    overflow:hidden;

    border-radius: 5px;

    font-family: sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    
    
    margin: auto;
    margin-top: 50px;

    min-width: 90vw;
}


  
.table thead,
.table tfoot {
  background-color: #114E28;
  color: #f3f3f3;
}

.table th,
.table td {
  border: 1px solid #114E28;

  border-right: none;
  border-left: none;

  padding: 15px 10px;
}

.table td {
  text-align: center;
}

.table thead tr:hover{
  background-color: #114E28 !important;
}

.table tr:hover{
  background-color: #efefef;
}

.table input{
    border: none;
    text-align: center;
}

.container{
  display: flex;
  flex-direction: column;
}

.container .buttonsContainer{
  display: flex;
  justify-content: center;

  gap: 30px;

  padding-top: 20px;
}

.checkbox:checked{
  accent-color: #114E28;
}

.deleteModal{
  display: inline-block !important;

  text-align: center;

  width: 100%;
}

.deleteModal p{
  font-size: 22px;
}

.deleteModalButtons{
  display: flex;
  align-items: center;
}

.buttonsLoader{
  display: flex;
  flex-direction: row;

  gap: 20px;

  align-items: center;

  flex-wrap: wrap;
}

.buttonsLoader p{
  text-align: center;
}

@media screen and (max-width: 800px) {
  .headerContainer{
    justify-content: space-between;

    flex-wrap: wrap;
  }

  .headerContainer div{
    display: flex;

    justify-content: center;

    width: fit-content;

    align-items: center;

    flex-wrap: nowrap;

    gap: 10px;
  }

  .headerContainer button{
    margin: 0px;
  }

  .input{
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .headerContainer{
    justify-content: center;

    align-items: center;

    flex-direction: column-reverse;

    gap: 30px;
  }

  .tableContainer{
    overflow-x: auto;
  }

  .input{
    width: 300px;
  }

  .buttonsLoader p{
    width: 100%;
  }

  .buttonsLoader{
    justify-content: center;
  }
}