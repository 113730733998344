.container{
    display: flex;

    flex-direction: row;
    justify-content: center;
    gap: 50px;

    flex-wrap: wrap;
}

.innerContainer{
    display: flex;
    flex-direction: column;

    gap: 5px;

    flex-wrap: nowrap;
}

.selectDG{
    font-size: 16px;
    min-width: 200px;

    max-width: 240px;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

label{
    font-size: 12px;
}