.input {
    /* Reset OS-specfic / browser-specific default style (see https://ishadeed.com/article/styling-the-good-old-button/#the-default-styles) */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff; /* to be replaced with your custom design */
    border: 1px solid #114E28; /* to be replaced with your custom design */
    /* Signify interactivity */
    border-radius: 5px; /* no physical button has a sharp corner; exact value to be replaced for your custom design */
    cursor: pointer;
    /* Match font style with the parent element */
    font: inherit;
    /* Make the button easy for touchscreen users to tap */
    min-height: 40px;
    min-width: 40px;

    transition: linear 0.2s;

    color: #114E28;
}

.input:focus {
    outline-offset: 4px; /* make Chrome show a focus ring around the button */
}

.input:hover{
    background-color: #114E28 !important;

    cursor: pointer;

    color: #fff;
}

.input:hover a{
    color: #fff;
}
