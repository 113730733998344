.headerContainer{

    padding-top: 20px;
      display: flex;
  
      justify-content: space-between;
  }
  
  .headerContainer button{
      margin: auto;
  }
  
  .table {
    border-collapse: collapse;

    border-radius: 5px;

    font-family: sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    
    
    margin: auto;
    margin-top: 20px;

    margin-bottom: 20vh;

    min-width: 90vw;
}


  
.table thead,
.table tfoot {
  background-color: #114E28;
  color: #f3f3f3;
}

.table th,
.table td {
  border: 1px solid #114E28;

  border-right: none;
  border-left: none;

  padding: 15px 10px;
}

.table td {
  text-align: center;
}

.table thead tr:hover{
  background-color: #114E28 !important;
}

.table tr:hover{
  background-color: #efefef;
}

.table input{
    
  min-width: 200px;
  max-width: fit-content;

  padding: 10px 20px;
  border: 1px solid #CCCCCC;

  border-radius: 5px;

  font-size: 16px;

  text-align: center;
}

.table input:hover{
  border-color: #999999;
}

.table input:focus{
  outline: none !important;


  border: 1.5px solid #114E28 !important;
  outline-color: #114E28 !important;
  box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.noDocuments{
  color: #114E28;
  font-size: 18px;

  font-weight: 500;
}

.deleteModal{
  display: inline-block !important;

  text-align: center;

  width: 100%;
}

.deleteModal p{
  font-size: 22px;
}

.deleteModalButtons{
  display: flex;
  align-items: center;
}

.tableContainer{
  overflow-x: auto;
}