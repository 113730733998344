.container{
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;

    padding-bottom: 20px;

    padding-top: 0 !important;
}

.container label{
    font-size: 18px;
}

.input{
    width: 80%;

    padding: 10px 20px;
    border: 1px solid #CCCCCC;

    border-radius: 5px;

    font-size: 16px;

    
}

.input:hover{
    border-color: #999999;
}

.input:focus{
    outline: none !important;


    border: 1.5px solid #114E28 !important;
    outline-color: #114E28 !important;
    box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.title{
    text-align: center;
    color: #114E28;

    font-size: 36px;

}

.documentsContainer{
    display: flex;

    flex-wrap: wrap;
    gap: 0px 50px;

    align-content: flex-start;

}

.documentsContainer span{
    min-width: 300px;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;
  
    background-color: rgba(199, 199, 199, 0.825);
  
    z-index: 50;
  
    position: fixed;
    left: 0;
    top: 0;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
  }

@media screen and (max-width: 800px) {
    .documentsContainer{
        justify-content: center;
    
    }
    
  }