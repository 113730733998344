.loaderContainer{
    margin-top: 50px;

    position: relative;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loaderContainer p{
    color: #114E28;
    font-size: 18px;
    text-align: center;
}