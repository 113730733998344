.button{

    font-size: 18px;

    padding: 15px 30px;

    border: 1px solid #114E28;

    background-color: #fff;

    transition: linear 0.2s;

    max-width: fit-content;

    color: #114E28;
    
    border-radius: 5px;

}

.button a{
    text-decoration: none;
    color: #114E28;
}

.button:hover{
    background-color: #114E28 !important;

    cursor: pointer;

    color: #f3f3f3;
}

.button:hover a{
    color: #f3f3f3;
}

.button:disabled{
    pointer-events: none;
    background-color: #e0e0e0;
    color: #585858;
    border-color: #585858;
}

.inverted{

    font-size: 18px;

    padding: 15px 30px;

    border: 1px solid #114E28;

    background-color: #114E28;

    transition: linear 0.2s;

    max-width: fit-content;

    color: #f3f3f3;
    
    border-radius: 5px;

}

.inverted a{
    text-decoration: none;
    color: #f3f3f3;
}

.inverted:hover{
    background-color: #f3f3f3 !important;

    cursor: pointer;

    color: #114E28;
}

.inverted:hover a{
    color: #114E28;
}

.inverted:disabled{
    pointer-events: none;
    background-color: #e0e0e0;
    color: #585858;
    border-color: #585858;
}

@media screen and (max-width: 650px) {
	.button, .inverted{
        padding: 15px 20px;
        font-size: 14px;
    }
}