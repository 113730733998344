.headerContainer{
    padding-top: 20px;
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.input{
  width: 300px;

  padding: 10px 20px;
  border: 1px solid #CCCCCC;
  border-radius: 5px;

  font-size: 18px;

  background-image: url('../img/icons/search_icon.svg');
  background-size: 30px;
  background-repeat: no-repeat;

  background-position: right;
}

.input:hover{
  border-color: #999999;
}

.input:focus{
  outline: none !important;


  border: 1.5px solid #114E28 !important;
  outline-color: #114E28 !important;
  box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.tableContainer{
  overflow-x: scroll;
  overflow-y: visible;
}

.table {
  border-collapse: collapse;

  border-radius: 5px;

  font-family: sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  
  
  margin: auto;
  margin-top: 20px;
  
  margin-bottom: 25vh;

  min-width: 90vw;
}



.table thead,
.table tfoot {
background-color: #114E28;
color: #f3f3f3;
}

.table th,
.table td {
border: 1px solid #114E28;

border-right: none;
border-left: none;

padding: 15px 10px;
}

.table td {
text-align: center;
}

.table thead tr:hover{
background-color: #114E28 !important;
}

.table tr:hover{
background-color: #efefef;
}

.table input{
max-width: fit-content;

border: 1px solid #CCCCCC;
padding: 10px 0px;

border-radius: 5px;

font-size: 14px;

text-align: center;
}

.table input:hover{
border-color: #999999;
}

.table input:focus{
outline: none !important;


border: 1.5px solid #114E28 !important;
outline-color: #114E28 !important;
box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.deleteModal{
  display: inline-block !important;

  text-align: center;

  width: 100%;
}

.deleteModal p{
  font-size: 22px;
}

.deleteModalButtons{
  display: flex;
  align-items: center;
}

.buttonContainer{
  display: flex;
  flex-direction: row;

  gap: 20px;

  align-items: center;

  flex-wrap: wrap;
}

.addModal{
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.addModal h2{
  color: #114E28;
  font-size: 28px;
}


.addModal input{
  min-width: 400px;
}

@media screen and (max-width: 600px) {
  .headerContainer{

    justify-content: space-between;

    flex-wrap: wrap;
  }

  .input{
    width: 250px;
  }

  .buttonContainer{
    display: flex;
    flex-wrap: nowrap;

    justify-content: center;
    gap: 10px;
  }

  .headerContainer button{
    margin: 0px;
  }


}

@media screen and (max-width: 520px) {
  .headerContainer{

    justify-content: center;
    gap: 20px;

    flex-direction: column-reverse;
  }

  .input{
    width: 250px;
  }

}