.container {
    background-image: url('../img/background.jpg');
    width: 100vw;
    height: 100vh;

    background-size:cover;

    position: relative;
}

.info{
    min-width: 50vw;

    background-color: #fff;

    box-shadow: #999999;

    height: fit-content;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 30px;

    border-radius: 20px;

    text-align: center;
}

.info img{
    max-width: 120px;
    display:block;
    margin:auto;
}

.info label{
    font-size: 18px;
    font-weight: 500;
    margin-left: 4vw;

    text-align:left;
    display: block;
}

.info input{

    font-size: 16px;

    width: 80%;
    margin-top: 1vh !important;

    border: 1px solid #CCCCCC;

    padding: 15px 10px;

    text-align: right;

    border-radius: 5px;

    transition: ease-in-out 0.1s;
}

.info input:nth-of-type(1){
    margin-bottom: 5vh !important;
}

.info input:hover {
    border-color: #999999;
}

.info input:focus {
    outline: none !important;


    border: 1.5px solid #114E28 !important;
    outline-color: #114E28 !important;
    box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.info button{

    float: right;

    margin-right: 4vw;
    margin-top: 5vh !important;
}

.errorMessage{
    color: red;
    font-size: 16px;
    text-align: center;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media screen and (max-width: 900px) {
	.info{
        min-width: 80vw;
    }
    
    .info label{
        font-size: 16px;
    }

    .info input{
        font-size: 14px;
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
	.info{
        min-width: 80vw;
    }
    
    .info label{
        font-size: 14px;
    }

    .info input{
        font-size: 12px;
        width: 85%;
    }
}