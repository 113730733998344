.container{
    max-width: 95vw;

    margin: 0vh auto;
    margin-top: 15vh;
}

.container h1{
    font-size: 64px;
    margin-bottom: 20px;

    color: #114E28;
}

.container h3{
    font-size: 18px;
    font-weight: 500;
}

.container hr{
    margin-bottom: 20px;
    border-top: 2px solid #114E28;
}

@media screen and (max-width: 600px) {

    .container h1{
        font-size: 48px;
    }

}