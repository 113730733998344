.control label{
    display: block;

    font-size: 12px;
}

.control input{
    min-width: 200px;
    max-width: fit-content;

    padding: 10px 20px;
    border: 1px solid #CCCCCC;

    border-radius: 5px;

    font-size: 16px;
}

.control input:hover{
    border-color: #999999;
}

.control input:focus{
    outline: none !important;


    border: 1.5px solid #114E28 !important;
    outline-color: #114E28 !important;
    box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}