.container{
    max-width: 95vw;

    margin: 0vh auto;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.container h1{
    font-size: 64px;
    margin-bottom: 20px;

    color: #114E28;
}

.container hr{
    margin-bottom: 20px;
    border-top: 2px solid #114E28;
}

.innerContainer{
    display: flex;

    flex-direction: row;
    justify-content: space-evenly;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;
}

.inputContainer{
    display: flex;
    flex-direction: column;
}

.inputContainer input{
    min-width: 350px;
}

.loaderContainer{
    min-width: 100vw;
    min-height: 100vh;

    background-color: rgba(199, 199, 199, 0.825);

    z-index: 50;

    position: fixed;
    left: 0;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

}

@media screen and (max-width: 900px) {

    .container h1{
        font-size: 48px;
    }

    .innerContainer{
        flex-direction: column;

        justify-content: center;

        align-items: center;

    }

    .inputContainer input{
        min-width: 300px;
    }

}