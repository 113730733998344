.table {
  border-collapse: collapse;

  border-radius: 5px;

  font-family: sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  
  
  margin: auto;
  margin-top: 20px;

  margin-bottom: 20vh;

  max-width: 90vw;
  min-width: 90vw;
}



.table thead,
.table tfoot {
background-color: #114E28;
color: #f3f3f3;
}

.table th,
.table td {
border: 1px solid #114E28;

border-right: none;
border-left: none;

padding: 15px 10px;
}

.table td {
text-align: center;
}

.table thead tr:hover{
background-color: #114E28 !important;
}

.table tr:hover{
background-color: #efefef;
}

.table input{
max-width: 100px;

border: 1px solid #CCCCCC;
padding: 10px 0px;

border-radius: 5px;

font-size: 14px;

text-align: center;
}

.table input:hover{
border-color: #999999;
}

.table input:focus{
outline: none !important;


border: 1.5px solid #114E28 !important;
outline-color: #114E28 !important;
box-shadow: 0 0 5px rgba(17, 78, 40, 0.5);
}

.container{
  display: flex;
  flex-direction: column;
}

.container .buttonsContainer{
  display: flex;
  justify-content: center;

  gap: 30px;

  padding-top: 20px;
}

.loaderContainer{
  min-width: 100vw;
  min-height: 100vh;

  background-color: rgba(199, 199, 199, 0.825);

  z-index: 50;

  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

}


@media screen and (max-width: 1000px) {
  .tableContainer{
    overflow-x:auto;
  }

  .buttonsContainer{
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    align-self:center; /* <-- this is the fix */

  }
  
}